import React from 'react';
import styled from 'styled-components';

const Header = styled.section`
  padding: 6rem 0 4rem 0;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    padding: 2rem 0;
  }

  .gatsby-resp-image-wrapper {
    margin-top: 2rem;
  }
`;

const Headline = styled.h1`
  margin: 0 0 1rem 0;
  line-height: 1.125;
  font-weight: 500;
  font-size: 2.6rem;
  letter-spacing: -1.25px;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    font-size: 1.75rem;
  }
`;

const Lead = styled.div`
  p {
    font-size: 1.5rem;
    line-height: 1.4;
    letter-spacing: -0.25px;

    @media (max-width: ${props => props.theme.responsive.mobile}) {
      font-size: 1.25rem;
    }
  }
`;

const LayoutHeader = ({ headline, lead }) => {
  return (
    <Header className="container">
      <div className="columns">
        <div className="column is-one-third is-hidden-mobile"></div>
        <div className="column is-two-thirds">
          {headline && (
            <Headline dangerouslySetInnerHTML={{ __html: headline }} />
          )}
          {lead && <Lead dangerouslySetInnerHTML={{ __html: lead }} />}
        </div>
      </div>
    </Header>
  );
};

export default LayoutHeader;
