import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import config from '../utils/siteConfig';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import LayoutHeader from '../components/LayoutHeader';
import LayoutProjectList from '../components/LayoutProjectList';
import LayoutCopy from '../components/LayoutCopy';
import LayoutList from '../components/LayoutList';

const LayoutTemplate = ({ data }) => {
  const { title, slug, headline, lead, contentModules } = data.contentfulLayout;
  const postNode = data.contentfulLayout;
  const pageLead = lead && lead.childMarkdownRemark.html || '';

  return (
    <Layout>
      <Helmet>
        <title>{`${config.siteTitle} | ${title}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} pageSEO />
      <article>
        <section className="section reveal">
          <LayoutHeader
            headline={headline.internal.content}
            lead={pageLead}
          />
          {contentModules !== null &&
            contentModules.map((module, i) => {
              if (module.__typename === 'ContentfulLayoutProjects') {
                return <LayoutProjectList projects={module.projects} key={i} />;
              }
              if (module.__typename === 'ContentfulLayoutList') {
                return (
                  <LayoutList
                    label={module.label}
                    content={module.content.childMarkdownRemark.html}
                    key={i}
                  />
                );
              }
              if (module.__typename === 'ContentfulLayoutCopy') {
                return (
                  <LayoutCopy
                    label={module.title}
                    width={module.width}
                    copy={module.copy.childMarkdownRemark.html}
                    key={i}
                  />
                );
              }
            })}
        </section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    contentfulLayout(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      headline {
        internal {
          content
        }
      }
      lead {
        childMarkdownRemark {
          html
        }
      }
      contentModules {
        __typename
        ... on ContentfulLayoutProjects {
          projects {
            title
            slug
            summary
            previewTitle
            thumbnailImage {
              title
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on ContentfulLayoutList {
          label
          content {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulLayoutCopy {
          title
          copy {
            childMarkdownRemark {
              html
            }
          }
          width
        }
      }
    }
  }
`;

export default LayoutTemplate;
