import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const ProjectList = styled.section`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 4rem;
  padding-bottom: 2rem;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const CaseStudy = styled.div`
  margin-bottom: 2rem;

  h3 {
    font-size: 1.125rem;
    text-transform: capitalize;
    line-height: 1.25;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  p {
    line-height: 1.25;
  }

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const ThumbnailImage = styled(Img)`
  margin-bottom: 1rem;
`;

const LayoutProjectList = ({ projects }) => {
  return (
    <ProjectList className="container">
      <div className="columns is-multiline is-mobile is-variable is-6-tablet is-2-mobile">
        {projects.map((project, i) => {
          return (
            <CaseStudy
              className="column is-one-third-tablet is-half-mobile casestudy-item"
              key={i}
            >
              <Link to={`/projects/${project.slug}/`}>
                <ThumbnailImage fluid={project.thumbnailImage.fluid} />
              </Link>
              <h3>
                <Link to={`/projects/${project.slug}/`}>{project.title}</Link>
              </h3>
              <p>{project.summary}</p>
            </CaseStudy>
          );
        })}
      </div>
    </ProjectList>
  );
};

export default LayoutProjectList;
