import React from 'react';
import styled from 'styled-components';

const Label = styled.span`
  color: ${props => props.theme.colors.secondary};
`;

const Copy = styled.section`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    padding: 2rem 0;
  }

  hr {
    margin: 2rem 0;
  }

  .highlight {
    background-color: ${props => props.theme.colors.tertiary};
    padding: 0 4px;
    margin: 0 -4px;
  }
`;

const LayoutCopy = ({ label, copy, width }) => {
  return (
    <Copy className="container">
      <div className="columns">
        <div className="column is-one-third">
          <Label>{label}</Label>
        </div>
        <div
          className={
            'column ' +
            (width === 'Default' || !width ? `is-two-thirds` : `is-half`)
          }
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      </div>
    </Copy>
  );
};

export default LayoutCopy;
