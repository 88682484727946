import React from 'react';
import styled from 'styled-components';

const Label = styled.span`
  color: ${props => props.theme.colors.secondary};
`;

const List = styled.section`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4rem 0;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    padding: 2rem 0;
  }

  ul {
    columns: 2;
  }
`;

const LayoutList = ({ label, content }) => {
  return (
    <List className="container">
      <div className="columns">
        <div className="column is-one-third">
          <Label>{label}</Label>
        </div>
        <div
          className="column is-two-thirds"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </List>
  );
};

export default LayoutList;
